body {
  padding: 15px !important;
  background-color: rgb(248, 249, 250) !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar-expand .navbar-nav .nav-link {
  margin: 0px 40px;
}

.top-button {
  width: 100%;
  margin-right: 1%;
}

.home-bottom-tap {
  border: 1px solid #d4d4d4;
  background-color: #FFFFFF;
  /* margin: 10px; */
}

.border-1px {
  border: 1px solid #d4d4d4;
}

.dropdown-menu.show {
  right: 0;
  left: auto !important;
}

.home-table-box {
  height: 320px;
  overflow-y: auto;
}

.home-bottom-tap .nav-item {
  float: left;
  margin-right: 2px;
}

.home-bottom-tap .nav-pills .nav-link {
  background-color: #a3a3a3;
  color: #FFFFFF;
  padding: 2px 8px;

}

.rdt_TableHeadRow {
  background-color: #3988ff !important;
  color: white;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n+3),
.btn-group>:not(.btn-check)+.btn {
  border-radius: 0.375rem !important;
  margin-right: 10px;
  max-width: 120px;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.subcontractor-switch-btn,
.datetime-switch-btn,
.station-switch-btn {
  max-width: 150px;
  font-size: 14px !important;
}

.btn {
  line-height: 1.5 rem;
}

.nav {
  display: block !important;
}

.nav-justified .nav-item {
  width: 130px;
  float: left;
}

.col.tap-border {
  border: #dee2e6 1px solid;
  border-top-width: 0;
}
.analytics .nav-tabs .nav-link.active{
  background-color: #0d6efd;
  color: #FFFFFF;
}
.tap-border{
  position: relative;
}
.tap-border .pagination{
  max-width: fit-content;
  margin-inline: auto;
}
.tab-content>.tab-pane {
  display: block !important;
  height: 0 !important;
  overflow: hidden !important;
}
.tab-content>.tab-pane.active {
  height: auto !important;
}

label.datetime-switch-btn.btn.btn-outline-primary {
  line-height: 24px;
}

.rdt_TableCell div:first-child{
  width: 100%;
  text-align: center;
  margin-left: 8px;

}
 
.swal2-html-container tbody, 
.swal2-html-container td, 
.swal2-html-container tfoot, 
.swal2-html-container th, 
.swal2-html-container thead, 
.swal2-html-container tr{
  border-width: 1px;
}

.m-b-main-box .text-start{
  padding-left: 10px;
}

.b-sub-box{
  background-color: #FFFFFF;
  border: 1px #dee2e6 solid;
  padding: 1px;
}

.rdt_TableCol{
  word-wrap: 'break-word'
}